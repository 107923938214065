/*
 * Observable based scrollIntoView( { behavior: 'smooth' } )
 * @param { Element } elem ::An Element on which we'll call scrollIntoView
 * @param { object } [options] ::An optional scrollIntoViewOptions dictionary
 * @return { Observable } (void) ::Resolves when the scrolling ends
 */
export function smoothScroll(elem, yOffset = 0, options = null) {
  return new Promise((resolve) => {
    if (!(elem instanceof Element)) {
      throw new TypeError('Argument 1 must be an Element');
    }
    let same = 0; // a counter
    let lastPos = null; // last known Y position
    // pass the user defined options along with our default
    const scrollOptions = Object.assign({
      behavior: 'smooth'
    }, options);

    // let's begin
    // elem.scrollIntoView(scrollOptions);
    window.scrollTo({...scrollOptions, top: elem.getBoundingClientRect().top + window.pageYOffset + yOffset })
    requestAnimationFrame(check);

    // this function will be called every painting frame
    // for the duration of the smooth scroll operation
    function check() {
      // check our current position
      const newPos = elem.getBoundingClientRect().top;

      if (newPos === lastPos) { // same as previous
        return resolve(null); // we've come to an halt
      } else {
        same = 0; // reset our counter
        lastPos = newPos; // remember our current position
      }
      // check again next painting frame
      requestAnimationFrame(check);
    }
  });
}
