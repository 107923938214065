import * as React from "react";

import Layout from "../../components/App/Layout";
import BlogRoll from "../../components/Blog/BlogRoll";
import Navbar from "../../components/App/Navbar";
import Footer from "../../components/App/Footer";
import { smoothScroll } from "../../utils/scrollUtil";

export default class BlogIndexPage extends React.Component {
  handleNewsletterClick = () => {
    const emailInputElement = document.querySelector('#email-input');
    smoothScroll(emailInputElement);
    setTimeout(() => {
      emailInputElement.focus();
    }, 1000);
  };
  render() {
    return (
      <Layout title={"Latest insight"}>
        <Navbar />
        <section className="section blog-section-list">
          <div className="container">
            <h1>
              Latest insights.
            </h1>
            <h5 className="subtitle">
              Read more about the many ways that intangible assets drive business performance.
            </h5>
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    );
  }
}
